import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAccount } from 'wagmi';
import { closestTo, isAfter, isEqual } from 'date-fns';

import { type TStyle, useClassnames } from '#hook/use-classnames';
import { useDispatch, useSelector } from '#core/store';
import { amplitude } from '#adapter/amplitude';
import { apiInject } from '#adapter/api/main/inject';
import { TCode200 } from '#adapter/api/main/api/v1/igos/get';
import { padStart } from '#helper/pad-start';

import { Logo } from '#component/logo';
import { Menu } from '#component/menu';
import { ButtonConnect } from '#component/button-connect';
import { slice as sliceMediaQuery } from '#component/media-query/slice';
import { ButtonSimple } from '#component/button-simple';
import { LangControls } from '#component/lang-controls';
import IconArrowGradient from '#component/icons/arrow-gradient';
import { CountdownDate } from '#component/countdown-date';
import { slice } from '#component/banner/slice';
import { IProps as ICountdownProps } from '#component/countdown';

import { DESKTOP_NAV_LINKS } from '#const';

import useCheckContracts from './use-check-claim';

import style from './index.module.pcss';

export interface IProps {
    readonly className?: TStyle | string
}

type TIgo = TCode200[number];

export const Header = (props: IProps) => {
    const cn = useClassnames(style, props.className);
    const { t } = useTranslation();
    const isDesktop = useSelector((store) => store[sliceMediaQuery.name].isDesktop);
    const hasClaimAvailable = useSelector((store) => store.claims.hasClaimAvailable);
    const { connector } = useAccount();
    const { pathname } = useLocation();
    const dispatch = useDispatch();

    useCheckContracts();

    const { data } = apiInject.endpoints.getListIGO.useQuery({
        query: {
            status: 'announce'
        }
    });

    const [currentIgo, setCurrentIgo] = useState<TIgo>();

    useEffect(() => {
        if(data) {
            const datesArr = data.reduce((acc, curr) => {
                if(curr.start_date && (isAfter(new Date(curr.start_date), new Date()))) {
                    acc.push(curr.start_date);
                }

                return acc;
            }, [] as Array<string>);
            const closestDate = closestTo(new Date(), datesArr || []);

            const payload = data.find((item) => {
                return (item.start_date && closestDate) && isEqual(item.start_date, closestDate);
            });

            setCurrentIgo(payload);
        }
    }, [data]);

    if(isDesktop) {
        return (
            <header className={cn('header')}>
                <Logo className={cn('header__logo')} />
                <div className={cn('header__nav')}>
                    {DESKTOP_NAV_LINKS.map((link) => {
                        if(link === 'launchpad') {
                            return (
                                <div
                                    className={cn('header__launchpad')}
                                    key={link}
                                >
                                    <Link
                                        key={link}
                                        to={`/${link}`}
                                        children={t(`components.header.links.${link}`)}
                                        className={cn('header__nav-link')}
                                    />
                                    {(currentIgo?.start_date) && (
                                        <CountdownDate
                                            date={new Date(currentIgo.start_date)}
                                            children={(duration) => {
                                                let content = '';

                                                if(duration.days >= 1) {
                                                    content = t('components.header.counter.days', {
                                                        count: duration.days
                                                    });
                                                } else if(duration.days < 1 && duration.hours > 1) {
                                                    content = t('components.header.counter.hours', {
                                                        hours  : padStart(duration.hours),
                                                        minutes: padStart(duration.minutes)
                                                    });
                                                } else if(duration.days === 0 && duration.hours === 0 && duration.minutes > 1) {
                                                    content = `${padStart(duration.minutes)}:${padStart(duration.seconds)}`;
                                                }

                                                if(content) {
                                                    return (
                                                        <div
                                                            className={cn('header__launchpad-text')}
                                                            role="button"
                                                            tabIndex={0}
                                                            onClick={() => dispatch(slice.actions.set(true))}
                                                        >
                                                            <IconArrowGradient />
                                                            <span className={cn('header__launchpad-text-span')}>{content}</span>
                                                        </div>
                                                    );
                                                }
                                            }}
                                        />
                                    )}
                                </div>
                            );
                        }

                        return (
                            <Link
                                key={link}
                                to={`/${link}`}
                                children={t(`components.header.links.${link}`)}
                                className={cn('header__nav-link')}
                            />
                        );
                    })}
                </div>
                <div className={cn('header__buttons')}>
                    <ButtonSimple
                        as={Link}
                        presetStyle="secondary-violet"
                        className={cn('header__button-simple')}
                        to="/claim"
                        children={(
                            <React.Fragment>
                                {t('components.header.buttons.claim')}
                                {hasClaimAvailable && <span className={cn('header__notification-dot')} />}
                            </React.Fragment>
                        )}
                    />
                    <ButtonConnect
                        onClick={() => {
                            amplitude.track('wallet_connected', {
                                wallet_name : connector?.name,
                                wallet_id   : connector?.id,
                                initiated_by: 'header_btn',
                                page_url    : pathname
                            });
                        }}
                    />
                    <LangControls />
                </div>
            </header>
        );
    }

    return (
        <header className={cn('header')}>
            <Logo className={cn('header__logo')} />
            <ButtonConnect />
            <Menu
                className={cn('header__menu')}
                igoStartDate={currentIgo?.start_date}
                igoStatus={currentIgo?.status as ICountdownProps['status']}
            />
        </header>
    );
};
