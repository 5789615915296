import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAccount } from 'wagmi';

import api from '#adapter/api/main';

import { QUERY_PARAMS_REF_KEY } from '..';

const LS_REF_KEY = 'referral-key';
const RW_REF_KEY = 'register-wellcome';

export const useAffilateLink = () => {
    const [params, setSearchParams] = useSearchParams();
    const queryKey = params.get(QUERY_PARAMS_REF_KEY);
    const lsKey = window.localStorage.getItem(LS_REF_KEY);
    const { data: userInfo } = api.endpoints.apiV1UsersWhoamiGet.useQuery();
    const [registerAffilate] = api.endpoints.apiV1AffiliateRegisterAffiliatePost.useMutation();
    const [registerWelcomeAffiliate, { isSuccess }] = api.endpoints.apiV1AffiliateWelcomeAffiliatePost.useMutation();
    const { isConnected, address } = useAccount();
    const isRegistered = window.localStorage.getItem(RW_REF_KEY) === 'true';
    const [registrationAttempted, setRegistrationAttempted] = useState(false);

    useEffect(() => {
        if(queryKey && !isRegistered) {
            void registerWelcomeAffiliate({
                body: {
                    link_key: (queryKey || lsKey) as string
                }
            });

            if(queryKey !== lsKey) {
                window.localStorage.setItem(LS_REF_KEY, queryKey);
            }
        }
    }, [isRegistered, queryKey]);

    // Обрабатываем успех выполнения запроса
    useEffect(() => {
        if(isSuccess && !isRegistered) {
            window.localStorage.setItem(RW_REF_KEY, 'true');
        }
    }, [isSuccess, isRegistered]);

    useEffect(() => {
        const hasReferralLink = !!(queryKey || lsKey);
        const differentLinkKey = userInfo?.affiliate_links?.[0]?.link_key !== lsKey;
        const notRegisteredByAffiliate = !userInfo?.registered_by_affiliate;

        const shouldRegisterAffiliate = isConnected
            && hasReferralLink
            && differentLinkKey
            && notRegisteredByAffiliate
            && userInfo !== undefined
            && !registrationAttempted;

        if(shouldRegisterAffiliate) {
            setRegistrationAttempted(true);
            void registerAffilate({
                body: {
                    new_wallet: address as `0x${string}`,
                    link_key  : (queryKey || lsKey) as string
                }
            })
                .unwrap()
                .then(() => {
                    localStorage.removeItem(LS_REF_KEY);
                    setSearchParams({});
                })
                .catch(() => {
                    setRegistrationAttempted(false);
                });
        }
    }, [isConnected, queryKey, lsKey, userInfo, address, registerAffilate, setSearchParams, registrationAttempted]);
};
