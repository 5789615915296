import { Address } from 'viem';
import { readContract } from '@wagmi/core';

import { TCode200 as IGames } from '#adapter/api/main/api/v1/games/get';
import { formatDecimals } from '#helper/format-decimals';
import config from '#core/wagmi';
import gameDev from '#abi/game-dev';
import holders from '#abi/holders';
import pd3 from '#abi/pd3';
import claimVesting from '#abi/vesting-table';
import abiGameToken from '#abi/game-token';
import affilate from '#abi/affiliate';

import { ETargets, EWallets, TType } from './use-contract-data';
import { getVestingDetails } from './vesting-contracts';
import { executeContractCalls } from './contracts';

// Определяем union тип для kind
export type TKindType = 'game' | 'taxes' | 'igo';

export interface IData {
    id: number,
    full_name?: string | null,
    short_name?: string | null,
    claimed: number,
    readyToClaim: number,
    transaction_id?: number | null,
    claim_wallet: Address,
    disabled: boolean,
    token_name?: string | null,
    tokenAllocated?: number | null,
    tokenStillVested?: number | null,
    kind?: TKindType | null,
    token_id?: number | null,
    game_id?: number | null,
    game_name?: string | null
}

// Define the type for the function arguments
interface IGetCombinedDataParams {
    games?: IGames,
    decimalsMap: Map<string, number>,
    type: TType,
    address: Address,
    target?: number | null,
    showAddress?: Address
}

export type TAbiType = typeof gameDev | typeof holders | typeof pd3 | typeof claimVesting | typeof affilate;

// Динамическая функция для импорта ABI в зависимости от типа
export const getAbiByType = (type: TType): TAbiType => {
    switch (type) {
        case 'claim_devs_wallet':
            return gameDev;
        case 'claim_holders_wallet':
            return holders;
        case 'claim_p3d_wallet':
            return pd3;
        case 'vesting_contract':
            return claimVesting;
        case 'claim_p3d_affilate_wallet':
            return affilate;
        default:
            throw new Error('Unknown type');
    }
};

interface IStreamValue { stream: number, value: bigint }

// Define the return type
export type TCombinedDataResult = Record<string, Array<IData>>;

export const getCombinedData = async ({
    games,
    type,
    address,
    decimalsMap,
    target,
    showAddress
}: IGetCombinedDataParams): Promise<TCombinedDataResult> => {
    // Проверка, что все обязательные данные переданы корректно
    if(!games) { return { igo: [], game: [], taxes: [] }; }
    const processedWallets = new Set<string>();

    const result: TCombinedDataResult = { igo: [], game: [], taxes: [] };

    // Параллельная обработка игр
    await Promise.allSettled(
        games.map(async (game) => {
            const isVestingContract = type === EWallets.VestingContract;
            const isClaimDevsWallet = type === EWallets.ClaimDevsWallet;
            const isBeneficiaryTarget = target === ETargets.Beneficiary;
            const isPlatformTokenHoldersTarget = target === ETargets.PlatformTokenHolders;

            const walletAddress = isVestingContract
                ? game.vesting_contract as `0x${string}`
                : game.token_data?.[type] as `0x${string}`;

            if(!walletAddress) {
                return null;
            }
            let beneficiary: Address | null = null;

            // Проверяем наличие claim_devs_wallet перед запросом
            if(game.token_data?.claim_devs_wallet) {
                beneficiary = await readContract(config, {
                    abi         : gameDev,
                    address     : game.token_data.claim_devs_wallet as `0x${string}`,
                    functionName: 'beneficiary'
                });
            }
            processedWallets.add(walletAddress);

            const wathdarwnsAdress: Address = showAddress ?? (isVestingContract && isBeneficiaryTarget && beneficiary ? beneficiary : address);

            const abi = getAbiByType(type);
            const { vestingId } = await getVestingDetails(walletAddress, abi, type, target, address);
            const contractData = await executeContractCalls(type, walletAddress, abi, wathdarwnsAdress, vestingId);
            const { withdrawableFunds, withdrawnFunds, unreleased } = contractData;

            if(isVestingContract) {
            // hard code надо бы с апи получать дефолт
                let decimals = formatDecimals(18);

                if(game?.token_data?.token_address) {
                    if(decimalsMap?.has(game?.token_data?.token_address)) {
                        decimals = formatDecimals(decimalsMap?.get(game?.token_data?.token_address || ''));
                    } else {
                        const decimalsFromContract = await readContract(config, {
                            abi         : abiGameToken,
                            address     : game?.token_data?.token_address as `0x${string}`,
                            functionName: 'decimals'
                        });

                        decimals = formatDecimals(decimalsFromContract);
                    }
                }

                // Если нет данных по контракту для текущей игры, пропускаем её
                if((!withdrawableFunds && !withdrawnFunds) && !isPlatformTokenHoldersTarget) {
                    return null;
                }

                const unreleasedWithDec = (Number(unreleased || 0n) / decimals);
                const claimed = (Number(withdrawnFunds || 0n) / decimals);
                const tokenAllocated = unreleasedWithDec + claimed;
                const readyToClaim = (Number(withdrawableFunds || 0n) / decimals);
                const tokensStillVested = unreleasedWithDec - readyToClaim;

                const isWalletDisabled = !!showAddress;
                const isReadyToClaimDisabled = !readyToClaim;
                const isBeneficiaryTargetDisabled = isBeneficiaryTarget && address !== beneficiary;

                const data = {
                    id            : game.id,
                    full_name     : game.full_name,
                    short_name    : game.short_name,
                    token_name    : game.token_data?.token_name,
                    token_id      : game.token_data?.id,
                    tokenAllocated,
                    tokensStillVested,
                    claimed,
                    readyToClaim,
                    transaction_id: vestingId,
                    claim_wallet  : walletAddress,
                    disabled      : isWalletDisabled || isReadyToClaimDisabled || isBeneficiaryTargetDisabled
                };

                if(!result[type]) {
                    result[type] = [];
                }

                result[type].push(data);
            } else {
            // Если нет данных по контракту для текущей игры, пропускаем её
                if(!withdrawableFunds && !withdrawnFunds && !beneficiary) {
                    return null;
                }

                game.revenue_streams?.forEach((revenueStream) => {
                    const withdrawable = (withdrawableFunds as Array<IStreamValue>)?.find(
                        (wf) => wf.stream === revenueStream.stream_id
                    );
                    const withdrawn = (withdrawnFunds as Array<IStreamValue>)?.find(
                        (wf) => wf.stream === revenueStream.stream_id
                    );

                    // Если поток найден в контрактах
                    if((withdrawable || withdrawn) || type === EWallets.ClaimP3DWallet) {
                        const decimals = formatDecimals(decimalsMap?.get(revenueStream.token_address || ''));

                        const claimed = (Number(withdrawn?.value || 0n) / decimals);
                        const readyToClaim = (Number(withdrawable?.value || 0n) / decimals);

                        const isWalletDisabled = !!showAddress;
                        const isReadyToClaimDisabled = !readyToClaim;
                        const isClaimDevsWalletDisabled = isClaimDevsWallet && address !== beneficiary;
                        const currency = revenueStream.kind === 'igo' ? 'USDT' : game.token_data?.token_name;

                        const data = {
                            id            : game.id,
                            full_name     : game.full_name,
                            short_name    : game.short_name,
                            token_name    : currency,
                            token_id      : game.token_data?.id,
                            claimed,
                            readyToClaim,
                            transaction_id: revenueStream.stream_id,
                            claim_wallet  : game.token_data?.[type] as Address,
                            disabled      : isWalletDisabled || isReadyToClaimDisabled || isClaimDevsWalletDisabled,
                            kind          : revenueStream.kind as TKindType
                        };

                        if(revenueStream.kind && !result[revenueStream.kind]) {
                            result[revenueStream.kind] = [];
                        }

                        if(revenueStream.kind && data) {
                            result[revenueStream.kind].push(data);
                        }
                    }
                });
            }
        }));

    return result;
};
