import { Address } from 'viem';
import { readContract } from '@wagmi/core';

import config from '#core/wagmi';

import { TAbiType } from './combined-data';
import { TType } from './use-contract-data';

interface IContractData {
    withdrawableFunds?: unknown,
    withdrawnFunds?: unknown,
    beneficiary?: Address,
    unreleased?: bigint
}

type TContractCall = (
    walletAddress: `0x${string}`,
    abi: TAbiType,
    address: `0x${string}`,
    vestingId?: number | null
) => Promise<IContractData>;

const contractCallsMap: Record<TType, Array<TContractCall>> = {
    'claim_devs_wallet': [
        async (walletAddress, abi) => {
            const withdrawableFunds = await readContract(config, {
                abi,
                address     : walletAddress,
                functionName: 'withdrawableFundsOf'
            });

            return { withdrawableFunds };
        },
        async (walletAddress, abi) => {
            const withdrawnFunds = await readContract(config, {
                abi,
                address     : walletAddress,
                functionName: 'withdrawnFundsOf'
            });

            return { withdrawnFunds };
        }
    ],
    'claim_holders_wallet': [
        async (walletAddress, abi, address) => {
            const withdrawableFunds = await readContract(config, {
                abi,
                address     : walletAddress,
                functionName: 'withdrawableFundsOf',
                args        : [address]
            });

            return { withdrawableFunds };
        },
        async (walletAddress, abi, address) => {
            const withdrawnFunds = await readContract(config, {
                abi,
                address     : walletAddress,
                functionName: 'withdrawnFundsOf',
                args        : [address]
            });

            return { withdrawnFunds };
        }
    ],
    'claim_p3d_wallet': [
        async (walletAddress, abi, address) => {
            const withdrawableFunds = await readContract(config, {
                abi,
                address     : walletAddress,
                functionName: 'withdrawableFundsOf',
                args        : [address]
            });

            return { withdrawableFunds };
        },
        async (walletAddress, abi, address) => {
            const withdrawnFunds = await readContract(config, {
                abi,
                address     : walletAddress,
                functionName: 'withdrawnFundsOf',
                args        : [address]
            });

            return { withdrawnFunds };
        }
    ],
    'vesting_contract': [
        // Вызов для получения unreleased
        async (walletAddress, abi, address, vestingId) => {
            if(!vestingId) {
                return { withdrawableFunds: 0 }; // Возвращаем 0, если нет vestingId
            }

            const unreleased = await readContract(config, {
                abi,
                address     : walletAddress,
                functionName: 'unreleasedFundsOf',
                args        : [address]
            });

            return { unreleased };
        },
        async (walletAddress, abi, address, vestingId) => {
            if(!vestingId) {
                return { withdrawableFunds: 0 }; // Возвращаем 0, если нет vestingId
            }

            const withdrawableFunds = await readContract(config, {
                abi,
                address     : walletAddress,
                functionName: 'withdrawableFundsOf',
                args        : [vestingId, address] // Используем vestingId и адрес
            });

            return { withdrawableFunds };
        },

        // Вызов для получения withdrawnFunds с использованием vestingId
        async (walletAddress, abi, address, vestingId) => {
            if(!vestingId) {
                return { withdrawnFunds: 0 }; // Возвращаем 0, если нет vestingId
            }

            const withdrawnFunds = await readContract(config, {
                abi,
                address     : walletAddress,
                functionName: 'withdrawnFundsOf',
                args        : [vestingId, address] // Используем vestingId и адрес
            });

            return { withdrawnFunds };
        }
    ],
    'claim_p3d_affilate_wallet': [
        async (walletAddress, abi, address) => {
            const withdrawableFunds = await readContract(config, {
                abi,
                address     : walletAddress,
                functionName: 'withdrawableFundsOf',
                args        : [address]
            });

            return { withdrawableFunds };
        },
        async (walletAddress, abi, address) => {
            const withdrawnFunds = await readContract(config, {
                abi,
                address     : walletAddress,
                functionName: 'withdrawnFundsOf',
                args        : [address]
            });

            return { withdrawnFunds };
        }
    ]
};

export const executeContractCalls = async (type: TType, walletAddress: `0x${string}`, abi: TAbiType, address: Address, vestingId?: number | null) => {
    const contractCalls = contractCallsMap[type] || [];
    const contractResults = await Promise.all(contractCalls.map((call) => call(walletAddress, abi, address, vestingId)));

    return contractResults.reduce((acc, result) => ({ ...acc, ...result }), {});
};
