import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { WagmiProvider } from 'wagmi';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { config } from '#core/wagmi';
import { store } from '#core/store';

import { MediaQuery } from '#component/media-query';
import { UserWeb2Provider } from '#component/user';
import { Routes } from '#route';

import '#locale';
import '#adapter/amplitude';
// import './assets/fonts/index.css';
import './index.pcss';

const container = document.getElementById(import.meta.env.APP_HTML_ROOT_ELEMENT_ID);

const queryClient = new QueryClient();

if(container) {
    const root = createRoot(container);

    root.render(
        <StrictMode>
            <WagmiProvider config={config}>
                <QueryClientProvider client={queryClient}>
                    <Provider store={store}>
                        <UserWeb2Provider>
                            <MediaQuery>
                                <HelmetProvider>
                                    <Routes />
                                </HelmetProvider>
                            </MediaQuery>
                        </UserWeb2Provider>
                    </Provider>
                </QueryClientProvider>
            </WagmiProvider>
        </StrictMode>
    );
}

console.info('App version: %s', __VERSION__);
