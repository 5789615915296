import { useEffect } from 'react';
import { useAccount } from 'wagmi';
import { useDispatch } from 'react-redux';
import { readContract } from '@wagmi/core';

import { api } from '#adapter/api/main';
import config from '#core/wagmi';

import { getAbiByType, TAbiType } from '#route/claim/helpers/combined-data';
import { EWallets } from '#route/claim/helpers/use-contract-data';

import { executeContractCalls } from './contracts';
import { setAvailableClaims } from './slice';

interface ITokenData {
    game_full_name?: string,
    claim_devs_wallet?: string,
    claim_holders_wallet?: string,
    claim_p3d_wallet?: string,
    claim_p3d_affilate_wallet?: string,
    game_vesting_contract?: string
}

interface IClaim {
    token: string,
    stream?: number,
    value: string
}

const handleVestingContract = async (
    walletAddress: `0x${string}`,
    abi: TAbiType,
    token: ITokenData,
    address: `0x${string}`
): Promise<Array<IClaim>> => {
    const claims: Array<IClaim> = [];

    if(!walletAddress) { return claims; }

    try {
        const detailsAll = await readContract(config, {
            abi,
            address     : walletAddress,
            functionName: 'detailsAll'
        });

        const vestingPromises = detailsAll.map(async (detail: any) => {
            const vestingId = Number(detail.id);

            try {
                const contractData = await executeContractCalls(EWallets.VestingContract, walletAddress, abi, address, vestingId);
                const withdrawableFunds = contractData.withdrawableFunds as bigint;

                if(withdrawableFunds > 0n) {
                    return {
                        token: token.game_full_name || '',
                        value: withdrawableFunds.toString()
                    };
                }
            } catch(error) {
                console.error(`Error executing contract call for Vesting ID ${vestingId}:`, error);
            }
        });

        const results = await Promise.all(vestingPromises);

        claims.push(...results.filter((claim): claim is IClaim => claim !== undefined));
    } catch(error) {
        console.error(`Error executing contract call for vesting contract at ${walletAddress}:`, error);
    }

    return claims;
};

const handleRegularContract = async (
    walletAddress: `0x${string}`,
    type: EWallets,
    abi: TAbiType,
    token: ITokenData,
    address: `0x${string}`,
    beneficiary?: `0x${string}`
): Promise<Array<IClaim>> => {
    const claims: Array<IClaim> = [];

    if(!walletAddress) { return claims; }

    try {
        const contractData = await executeContractCalls(type, walletAddress, abi, address);
        const valuesArray = Array.isArray(contractData.withdrawableFunds)
            ? contractData.withdrawableFunds
            : [contractData.withdrawableFunds];

        for(const { stream, value } of valuesArray) {
            const withdrawableValue = value as bigint;

            if(withdrawableValue > 0n) {
                // Replace `continue` with an `if` condition to skip adding to the array
                if(type === EWallets.ClaimDevsWallet && address !== beneficiary) {
                    return claims;
                }

                claims.push({
                    token: token.game_full_name || '',
                    stream,
                    value: withdrawableValue.toString()
                });
            }
        }
    } catch(error) {
        console.error(`Error executing contract call for ${walletAddress}:`, error);
    }

    return claims;
};

const handleContract = async (
    walletAddress: `0x${string}` | undefined,
    type: EWallets,
    token: ITokenData,
    address: `0x${string}`
): Promise<Array<IClaim>> => {
    if(!walletAddress) {
        console.warn(`Contract address is null for token: ${token.game_full_name || 'Unknown'}`);

        return [];
    }

    let beneficiary: `0x${string}` | undefined;

    if(token.claim_devs_wallet) {
        beneficiary = await readContract(config, {
            abi         : getAbiByType(EWallets.ClaimDevsWallet),
            address     : token.claim_devs_wallet as `0x${string}`,
            functionName: 'beneficiary'
        });
    }

    const abi = getAbiByType(type);

    if(type === EWallets.VestingContract) {
        return handleVestingContract(walletAddress, abi, token, address);
    }

    return handleRegularContract(walletAddress, type, abi, token, address, beneficiary);
};

const useCheckContracts = () => {
    const { address } = useAccount();
    const { data: tokenData } = api.endpoints.apiV1TokenDataGet.useQuery();
    const { data: userInfo } = api.endpoints.apiV1UsersWhoamiGet.useQuery();
    const dispatch = useDispatch();

    useEffect(() => {
        if(tokenData && address && userInfo !== undefined) {
            const checkContracts = async () => {
                let allClaims: Array<IClaim> = [];

                const contractPromises: Array<Promise<Array<IClaim>>> = [];

                for(const token of tokenData) {
                    const contracts = [
                        { walletAddress: token.claim_devs_wallet as `0x${string}`, type: EWallets.ClaimDevsWallet },
                        { walletAddress: token.claim_holders_wallet as `0x${string}`, type: EWallets.ClaimHoldersWallet },
                        { walletAddress: token.claim_p3d_wallet as `0x${string}`, type: EWallets.ClaimP3DWallet },
                        { walletAddress: token.claim_p3d_affilate_wallet as `0x${string}`, type: EWallets.AffiliatesWallet },
                        { walletAddress: token.game_vesting_contract as `0x${string}`, type: EWallets.VestingContract }
                    ];

                    contracts.forEach(({ walletAddress, type }) => {
                        contractPromises.push(handleContract(walletAddress, type, token as ITokenData, address));
                    });
                }

                // Use `Promise.all` to wait for all contract calls to finish
                const contractResults = await Promise.all(contractPromises);

                allClaims = contractResults.flat();

                if(allClaims.length > 0) {
                    dispatch(setAvailableClaims({
                        claims   : allClaims,
                        timestamp: Date.now()
                    }));
                }
            };

            checkContracts().catch((error) => console.error('Error in checkContracts:', error));
        }
    }, [tokenData, address, userInfo, dispatch]);
};

export default useCheckContracts;
