import { Address } from 'viem';
import { readContract } from '@wagmi/core';

import config from '#core/wagmi';

import { EWallets, TType } from '#route/claim/helpers/use-contract-data';
import { TAbiType } from '#route/claim/helpers/combined-data';

interface IContractData {
    withdrawableFunds?: unknown
}

type TContractCall = (
    walletAddress: `0x${string}`,
    abi: TAbiType,
    withdrawAddress: Address,
    vestingId?: number
) => Promise<IContractData>;

const contractCallsMap: Record<string, TContractCall> = {
    [EWallets.ClaimDevsWallet]: async (walletAddress, abi) => {
        const withdrawableFunds = await readContract(config, {
            abi,
            address     : walletAddress,
            functionName: 'withdrawableFundsOf'
        });

        return { withdrawableFunds };
    },
    [EWallets.ClaimHoldersWallet]: async (walletAddress, abi, withdrawAddress) => {
        const withdrawableFunds = await readContract(config, {
            abi,
            address     : walletAddress,
            functionName: 'withdrawableFundsOf',
            args        : [withdrawAddress]
        });

        return { withdrawableFunds };
    },
    [EWallets.ClaimP3DWallet]: async (walletAddress, abi, withdrawAddress) => {
        const withdrawableFunds = await readContract(config, {
            abi,
            address     : walletAddress,
            functionName: 'withdrawableFundsOf',
            args        : [withdrawAddress]
        });

        return { withdrawableFunds };
    },
    [EWallets.AffiliatesWallet]: async (walletAddress, abi, withdrawAddress) => {
        const withdrawableFunds = await readContract(config, {
            abi,
            address     : walletAddress,
            functionName: 'withdrawableFundsOf',
            args        : [withdrawAddress]
        });

        return { withdrawableFunds };
    },
    [EWallets.VestingContract]: async (walletAddress, abi, withdrawAddress, vestingId) => {
        if(!vestingId) {
            return { withdrawableFunds: 0 };
        }

        const withdrawableFunds = await readContract(config, {
            abi,
            address     : walletAddress,
            functionName: 'withdrawableFundsOf',
            args        : [vestingId, withdrawAddress]
        });

        return { withdrawableFunds };
    }
};

export const executeContractCalls = async (
    type: TType,
    walletAddress: `0x${string}`,
    abi: TAbiType,
    withdrawAddress: Address,
    vestingId?: number
) => {
    const contractCall = contractCallsMap[type];

    if(!contractCall) {
        throw new Error(`Unsupported contract type: ${type}`);
    }

    try {
        const contractData = await contractCall(walletAddress, abi, withdrawAddress, vestingId);

        return contractData;
    } catch(error) {
        console.error(`Error executing contract call for ${walletAddress}:`, error);

        return { withdrawableFunds: 0 };
    }
};
