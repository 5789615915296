import { useEffect, ReactNode, useRef } from 'react';
import { useSignMessage } from 'wagmi';
import { watchAccount, WatchAccountReturnType } from '@wagmi/core';

import { config } from '#core/wagmi';
import api from '#adapter/api/main';

export interface IProps {
    readonly children: ReactNode
}

export const UserWeb2Provider = (props: IProps) => {
    const { signMessageAsync } = useSignMessage({
        config
    });
    const accountRef = useRef<`0x${string}` | null>(null);
    const [whoamiRequest] = api.endpoints.apiV1UsersWhoamiGet.useLazyQuery();
    const [logoutRequest] = api.endpoints.apiV1UsersLogoutPost.useMutation();
    const [authenticateRequest] = api.endpoints.apiV1UsersAuthenticatePost.useMutation();
    const [nonceRequest] = api.endpoints.apiV1AuthNoncePost.useMutation();
    const unwatchRef = useRef<WatchAccountReturnType | null>(null);

    const authenticate = async (address: `0x${string}`) => {
        if(address) {
            try {
                const { nonce } = await nonceRequest({
                    body: {
                        user_address: address
                    }
                }).unwrap();

                // const timestamp = new Date().getTime();

                const signedMessage = await signMessageAsync({
                    account: address,
                    message: `Authenticate: ${nonce}`
                });

                void authenticateRequest({
                    body: {
                        address,
                        nonce    : nonce as string,
                        signature: signedMessage
                        // timestamp
                    }
                })
                    .unwrap()
                    .then(() => {
                        api.util.resetApiState();
                    });
            } catch(error) {
                console.error(error);
            }
        }
    };

    useEffect(() => {
        if(!unwatchRef.current) {
            unwatchRef.current = watchAccount(config, {
                onChange: async (data) => {
                    if(data.status === 'disconnected') {
                        void logoutRequest();

                        accountRef.current = null;

                        // чистим весь стейт ртк при логауте
                        api.util.resetApiState();
                    }

                    if(data.status === 'connected') {
                        // Смена аккаунта
                        if(accountRef.current && accountRef.current !== data.address) {
                            void logoutRequest()
                                .unwrap()
                                .then(() => {
                                    // @TODO избавиться от релоада при переключении кошельков, нужно обработать все возможные сайд эффекты
                                    // rkt api + все нужные контракты (там много:))
                                    window.location.reload();
                                });

                            return;
                        }

                        try {
                            const whoamiPayload = await whoamiRequest();

                            if(whoamiPayload.data?.wallet !== data.address) {
                                void authenticate(data.address);
                            }
                        } catch(error) {
                            console.error(error);
                        }

                        accountRef.current = data.address;
                    }
                }
            });
        }
    }, []);

    return props.children;
};
