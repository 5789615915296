import { readContract } from '@wagmi/core';

import config from '#core/wagmi';

import { TAbiType } from './combined-data';
import { ETargets, EWallets, TTarget, TType } from './use-contract-data';

interface IVestingDetails {
    vestingId: number
}

export const getVestingDetails = async (
    walletAddress: `0x${string}`,
    abi: TAbiType,
    type: TType,
    target?: TTarget | null,
    address?: `0x${string}`
): Promise<IVestingDetails> => {
    const defaultVestingDetails: IVestingDetails = {
        vestingId: 0
    };

    if(type !== EWallets.VestingContract) {
        return defaultVestingDetails;
    }

    try {
        const detailsAll = await readContract(config, {
            abi,
            address     : walletAddress,
            functionName: 'detailsAll'
        });

        type TDetailsAllType = (typeof detailsAll)[number];

        let vestingDetail: TDetailsAllType | undefined;

        if(target === ETargets.Holders && address) {
            vestingDetail = detailsAll.find((detail) => detail.beneficiary === address);
        } else if(target !== null) {
            vestingDetail = detailsAll.find((detail) => detail.target === target);
        }

        if(vestingDetail) {
            return {
                vestingId: Number(vestingDetail.id)
            };
        }

        return defaultVestingDetails;
    } catch(error) {
        console.error(`Error fetching vesting details for ${walletAddress}:`, error);

        return defaultVestingDetails;
    }
};
